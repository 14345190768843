const componentName = 'product-shipping-prices';
const featureName = 'ProductShippingPrices';

export const PRODUCT_SHIPPING_PRICES_NAMES = {
    feature: componentName,
    component: componentName,
    api: '',
    httpApi: '',
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;

export const PRODUCT_SHIPPING_PRICES_CLASS_NAMES = {
    shippingPricesContainer: 'product-shipping-prices',
    shippingMethod: 'product-shipping-prices__shipping-method',
    singleShippingInfo: 'product-shipping-prices__shipping-info_single-shipping',
    multipleShippingInfo: 'product-shipping-prices__shipping-info_multiple-shippings',
    description: 'product-shipping-prices__description'
} as const;
