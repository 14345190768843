import {
    IStorefrontSettingsQuery,
    TInpostPayWidgetSettings,
    TSkinSettings,
    TStorefrontSettingsStore
} from '@storefrontFeatures/storefront_settings/state/storefront_settings_types';
import { Locale } from '@dreamcommerce/star_core';
import { Observable } from 'rxjs';
import { TLocaleIsoUnderscore } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/locale/locale_types';
import { select } from '@ngneat/elf';
import {
    HTTP_API_PREFIX,
    INPOST_PAY_WIDGET_SETTINGS_KEY,
    PAGE_SETTINGS_KEY,
    SKIN_SETTINGS_KEY
} from '@storefrontRoot/features/storefront_settings/storefront_settings_constants';
import { Country } from '@storefrontRoot/models/country/country';

export class StorefrontSettingsRepository implements IStorefrontSettingsQuery {
    constructor(protected storefrontSettingsStore: TStorefrontSettingsStore) {}

    public getSkinSettings(): TSkinSettings {
        return this.storefrontSettingsStore.getValue()[SKIN_SETTINGS_KEY];
    }

    public getPageSettings(): Record<string, string> {
        return this.storefrontSettingsStore.getValue()[PAGE_SETTINGS_KEY];
    }

    public getInpostPayWidgetSettings(): TInpostPayWidgetSettings | null {
        return this.storefrontSettingsStore.getValue()[INPOST_PAY_WIDGET_SETTINGS_KEY];
    }

    public selectSkinSettings$(): Observable<TSkinSettings> {
        return this.storefrontSettingsStore.pipe(select((state) => state[SKIN_SETTINGS_KEY]));
    }

    public selectPageSettings$(): Observable<Record<string, string>> {
        return this.storefrontSettingsStore.pipe(select((state) => state[PAGE_SETTINGS_KEY]));
    }

    public selectInpostPayWidgetSettings$(): Observable<TInpostPayWidgetSettings | null> {
        return this.storefrontSettingsStore.pipe(select((state) => state[INPOST_PAY_WIDGET_SETTINGS_KEY]));
    }

    public getCountries(): Country[] {
        return this.storefrontSettingsStore.getValue().countries;
    }

    public selectFrontstoreApiUrl$(): Observable<string> {
        return this.selectSkinSettings$().pipe(
            select(({ locale }) => {
                const localeObject = new Locale(locale as TLocaleIsoUnderscore);
                return `/${HTTP_API_PREFIX}/${localeObject}`;
            })
        );
    }
}
