export const GLOBAL_FLASH_MESSENGER_CONTAINER_NAME = 'global';

const componentName = 'flash-messenger';
const featureName = 'FlashMessenger';

export const COOKIE_FLASH_MESSENGER_NAME = 'sfm';

export const FLASH_MESSENGER_NAMES = {
    feature: componentName,
    component: componentName,
    api: `${featureName}Api`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;
