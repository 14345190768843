import { IGlobalInitializersContainer } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/core/global_containers/initializers/global_initializers_container_types';
import { EventMessage, FeatureCore, getStarCoreEnvironment, IFeatureSystemApi, IModulesContainer } from '@dreamcommerce/star_core';
import {
    COMPONENTS_CONTAINER_NAME,
    COMPONENTS_RESOLVER_NAME
} from '@storefrontCoreFeatures/components/management/components_management_constants';
import { IComponentsCreator, TComponentCreatorTypes } from '@storefrontCoreFeatures/components/management/creators/components_creator_types';
import { TComponentName } from '@storefrontCoreFeatures/components/management/components_management_types';
import { COMPONENT_CREATOR_TYPE } from '@storefrontCoreFeatures/components/management/creators/components_creator_constants';
import { COMPONENTS_MANAGEMENT_EVENTS } from '@storefrontCoreFeatures/components/management/components_management_messages_names';
import { COMPONENT_TYPE_ATTRIBUTE_NAME } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_constants';
import { ComponentResolverError } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_error';
import {
    IComponentsResolver,
    TComponentsResolverConstructorOptions
} from '@storefrontCoreFeatures/components/management/resolver/components_resolver_types';
import uniqBy from 'lodash/uniqBy';
import { yieldOrContinue } from 'main-thread-scheduling';

export class ComponentsResolver extends FeatureCore implements IComponentsResolver {
    public moduleName = COMPONENTS_RESOLVER_NAME;

    readonly #componentCreators: Record<TComponentCreatorTypes, IComponentsCreator>;
    readonly #dynamicComponentsNames: Record<string, boolean>;

    #componentsRegistry: IModulesContainer<any>;
    #globalInitializersContainer: IGlobalInitializersContainer;
    #featureSystemApi: IFeatureSystemApi;

    constructor({
        globalCoresContainersRegistry,
        globalInitializersContainer,
        componentCreators,
        featureSystemApi,
        dynamicComponentsNames
    }: TComponentsResolverConstructorOptions) {
        super();

        this.#componentsRegistry = globalCoresContainersRegistry.getContainer(COMPONENTS_CONTAINER_NAME);
        this.#globalInitializersContainer = globalInitializersContainer;
        this.#featureSystemApi = featureSystemApi;
        this.#componentCreators = componentCreators;
        this.#dynamicComponentsNames = dynamicComponentsNames;
    }

    public async resolve(components: HTMLElement[]): Promise<void> {
        try {
            /**
             * In case of use other framework for components, this has to be changed, we can have
             * duplicate components in e.g. preact to initialize
             */
            const componentsToResolve = this._getComponentsToResolve(components);

            const promises = componentsToResolve.map(async ($component) => {
                await yieldOrContinue('background');

                const componentName = $component.tagName.toLowerCase();

                if (!this.#componentsRegistry.getInstance(componentName)) await this._resolveComponentClass(componentName);
            });

            await Promise.all(promises);
        } catch (error) {
            //TODO @zefirek
            // Sprawdzć kejs gdy try jest na sam promise, dlaczego error w bussach jest undefined
            console.error(error);
        }
    }

    public registerComponents(componentsClasses: any[]): void {
        componentsClasses.forEach((componentClass) => {
            const $component = document.querySelector(componentClass.moduleName);

            const componentCreator = this._getComponentCreator($component);

            if (!componentCreator) return;

            componentCreator.create({
                componentClass,
                htmlElement: $component,
                componentName: componentClass.moduleName
            });

            this.eventBus.emit(
                new EventMessage(COMPONENTS_MANAGEMENT_EVENTS.componentCreated, {
                    componentName: componentClass.moduleName
                })
            );
        });
    }

    private _getComponentCreator($component?: HTMLElement): IComponentsCreator | undefined {
        const componentType = $component?.getAttribute(COMPONENT_TYPE_ATTRIBUTE_NAME) as TComponentCreatorTypes;

        return this.#componentCreators[componentType ?? COMPONENT_CREATOR_TYPE.webComponent] as IComponentsCreator;
    }

    private _getComponentsToResolve(components: HTMLElement[]): HTMLElement[] {
        return uniqBy(components, (element) => {
            return element.tagName.toLowerCase();
        });
    }

    private async _resolveComponentClass(componentName: TComponentName): Promise<void> {
        const componentInitializer = this.#globalInitializersContainer.getFeatureRegistrationItem(componentName);

        const { globalCoresContainersRegistry } = getStarCoreEnvironment();

        if (globalCoresContainersRegistry.hasFeatureBeenRegistered(componentName)) return;

        if (!componentInitializer && !this.#dynamicComponentsNames[componentName]) return;

        if (!componentInitializer) {
            await this.#featureSystemApi.registerDynamic(componentName);

            return;
        }

        await this.#featureSystemApi.register(componentInitializer.initializerClass);

        const registeredComponentClass = this.#componentsRegistry.getInstance(componentName);

        if (!registeredComponentClass) throw new ComponentResolverError(`Initializer for ${componentName} hasn't returned a component class`);
    }
}
