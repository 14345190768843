const featureName = 'Authentication';

export const AUTHENTICATION = {
    feature: featureName,
    component: 'auth-controller',
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;

export const AUTHENTICATION_STATUS = {
    authorized: 'authorized',
    unauthorized: 'unauthorized'
} as const;

export const AUTH_COOKIE_NAME = 'authCookie';

const SECOND = 1000;
export const COOKIE_TIME_TO_LIVE = 30 * SECOND;
