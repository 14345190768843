const featureName = 'RecaptchaV3';

export const RECAPTCHA_NAMES = {
    feature: featureName,
    component: '',
    api: `${featureName}Api`,
    httpApi: '',
    service: '',
    store: ''
} as const;
