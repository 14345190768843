import { FEATURE_CORES_TYPES, SyncFeatureInitializer, TFeatureRecord } from '@dreamcommerce/star_core';
import { FLASH_MESSENGER_NAMES } from '@storefrontRoot/features/flash_messenger/flash_messenger_constants';
import { FlashMessengerApi } from '@storefrontRoot/features/flash_messenger/api/flash_messenger_api';

export class FlashMessengerApiInitializer extends SyncFeatureInitializer {
    public static featureName = FLASH_MESSENGER_NAMES.api;

    public init(): TFeatureRecord {
        return {
            cores: [
                {
                    type: FEATURE_CORES_TYPES.api,
                    instance: new FlashMessengerApi()
                }
            ]
        };
    }
}
