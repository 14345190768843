const featureName = 'ResolutionDetector';

export const RESOLUTION_DETECTOR_NAMES = {
    feature: featureName,
    component: '',
    api: `${featureName}Api`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;

export const BREAKPOINTS_NAMES = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    xxl: 'xxl'
} as const;

export const BREAKPOINT_VALUE_TO_NAME_MAP = {
    576: BREAKPOINTS_NAMES.xs,
    768: BREAKPOINTS_NAMES.sm,
    1000: BREAKPOINTS_NAMES.md,
    1200: BREAKPOINTS_NAMES.lg,
    1440: BREAKPOINTS_NAMES.xl,
    1920: BREAKPOINTS_NAMES.xxl
} as const;

export const BREAKPOINTS_NAME_TO_VALUE_MAP = {
    [BREAKPOINTS_NAMES.xs]: 576,
    [BREAKPOINTS_NAMES.sm]: 768,
    [BREAKPOINTS_NAMES.md]: 1000,
    [BREAKPOINTS_NAMES.lg]: 1200,
    [BREAKPOINTS_NAMES.xl]: 1440,
    [BREAKPOINTS_NAMES.xxl]: 1920
} as const;

export const RESOLUTION_DETECTOR_RESIZE_EVENT_THROTTLE = 100;
