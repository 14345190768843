import { StoreFactoryInitializer } from '@dreamcommerce/star_core';

import { StorefrontAppInitializer } from '@storefrontApp/initializer/storefront_app_initializer';
import { StorefrontComponentsManagementInitializer } from '@storefrontCoreFeatures/components/management/components_management_initializer';
import { StorefrontHTTPRequesterInitializer } from '@storefrontCoreFeatures/http_requester/storefront_http_requester_initializer';
import { FlashMessengerApiInitializer } from '@storefrontFeatures/flash_messenger/flash_messenger_api_initializer';
import { StorefrontSettingsInitializer } from '@storefrontFeatures/storefront_settings/storefront_settings_initializer';

export const STOREFRONT_FEATURES_TO_INITIALIZE = [
    StoreFactoryInitializer,
    StorefrontHTTPRequesterInitializer,
    FlashMessengerApiInitializer,
    StorefrontComponentsManagementInitializer,

    //@important StorefrontAppInitilizer must be the last one !!!
    StorefrontSettingsInitializer,
    StorefrontAppInitializer
];
