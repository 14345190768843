import { FEATURE_CORES_TYPES, SyncFeatureInitializer, TFeatureRecord } from '@dreamcommerce/star_core';
import { StorefrontSettingsApi } from '@storefrontRoot/features/storefront_settings/api/storefront_settings_api';
import { PageManagerRenderedHandler } from '@storefrontRoot/features/storefront_settings/events/page_manager_rendered_handler';
import { StorefrontSettingsRepository } from '@storefrontRoot/features/storefront_settings/state/repositories/storefront_settings_repository';
import { StorefrontSettingsService } from '@storefrontRoot/features/storefront_settings/state/services/storefront_settings_service';
import { createStorefrontSettingsStore } from '@storefrontRoot/features/storefront_settings/state/stores/storefront_settings_store';
import { STOREFRONT_SETTINGS_NAMES } from '@storefrontRoot/features/storefront_settings/storefront_settings_constants';

export class StorefrontSettingsInitializer extends SyncFeatureInitializer {
    public static featureName = STOREFRONT_SETTINGS_NAMES.feature;

    public init(): TFeatureRecord {
        const store = createStorefrontSettingsStore();
        const service = new StorefrontSettingsService(store);
        const query = new StorefrontSettingsRepository(store);

        return {
            cores: [
                {
                    type: FEATURE_CORES_TYPES.api,
                    instance: new StorefrontSettingsApi({ service, query })
                },
                {
                    type: FEATURE_CORES_TYPES.eventHandler,
                    instance: new PageManagerRenderedHandler(service)
                }
            ]
        };
    }
}
