export const PRODUCT_GALLERY_NAMES = {
    feature: 'product-gallery',
    component: 'product-gallery',
    api: '',
    service: '',
    store: ''
} as const;

export const PRODUCT_GALLERY_CLASS_NAMES = {
    component: 'product-gallery',
    gallery_opener: 'js__open-gallery',
    arrows_container: 'splide__arrows'
} as const;
