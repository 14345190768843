const featureName = 'Search';
const componentName = 'h-storefront-search';

export const SEARCH_NAMES = {
    feature: componentName,
    component: componentName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    viewService: `${featureName}ViewService`,
    historyStore: `${featureName}HistoryStore`,
    resultsStore: `${featureName}ResultsStore`
} as const;

export const SEARCH_MIN_QUERY_LENGTH = 1;

export const SEARCH_CONTEXT_NAME = 'searchContext';

export const SEARCH_EVENT_NAMES = {
    searchPhraseUpdated: 'search.searchPhraseUpdated',
    searchPhraseSubmitted: 'search.searchPhraseSubmitted',
    removeHistoryItem: 'search.removeHistoryItem',
    loadMoreProducts: 'search.loadMoreProducts'
} as const;

export const SEARCH_HISTORY_KEY = 'search-history';
