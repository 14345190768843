export const PRODUCT_PRICE_NAMES = {
    feature: 'product-price',
    component: 'product-price'
} as const;

export const PRODUCT_PRICE_CLASS_NAMES = {
    priceBase: 'product-price__base-price',
    regularPrice: 'product-price__regular-price',
    lowestHistoricalPriceContainer: 'product-price__lowest-historical-price',
    priceValue: 'js__price-value',
    priceValueInactive: 'js__price__value_inactive',
    specialOfferPriceValue: 'js__special-offer-price-value',
    specialOfferPercentageValue: 'js__special-offer-percentage-value',
    netPricesContainer: 'js__product-price-net',
    grossPricesContainer: 'js__product-price-gross',
    priceContainer: 'js__product-price',
    currencyPriceContainer: 'js__product-currency-price',
    lowestHistoricalPricePercentageValue: 'js__lowest-historical-price-percentage-value',
    specialOfferDuration: 'js__special-offer-duration-value',
    specialOfferContainer: 'js__special-offer-container',
    noSpecialOfferContainer: 'js__no-special-offer-container'
} as const;
