const featureName = 'AddToFavourties';
const componentName = 'add-to-favourites';

export const ADD_TO_FAVOURITES_NAMES = {
    feature: componentName,
    component: componentName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;

export const FAVOURITES_STORE_KEY_NAME = 'addedToFavourites';

const FAVOURITES_EVENTS_NAME = 'addedToFavourites';

export const FAVOURITES_EVENTS = {
    added: `${FAVOURITES_EVENTS_NAME}.added`,
    removed: `${FAVOURITES_EVENTS_NAME}.removed`
} as const;
