const featureName = 'PageManager';

export const PAGE_MANAGER_NAMES = {
    feature: featureName,
    component: '',
    api: `${featureName}Api`,
    service: `${featureName}Service`,
    store: ''
} as const;

export const TURBO_EVENTS = {
    load: 'turbo:load',
    click: 'turbo:click',
    beforeVisit: 'turbo:before-visit',
    visit: 'turbo:visit',
    submitStart: 'turbo:submit-start',
    beforeFetchRequest: 'turbo:before-fetch-request',
    beforeFetchResponse: 'turbo:before-fetch-response',
    submitEnd: 'turbo:submit-end',
    beforeCache: 'turbo:before-cache',
    beforeRender: 'turbo:before-render',
    render: 'turbo:render'
} as const;
