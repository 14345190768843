export const FORM_CONTROLS_TYPES = {
    text: 'text',
    radio: 'radio',
    checkbox: 'checkbox',
    number: 'number',
    range: 'range',
    textarea: 'textarea',
    select: 'select',
    multiSelect: 'multiSelect',
    hidden: 'hidden',
    email: 'email',
    tel: 'tel',
    password: 'password',
    file: 'file',
    colorSwatches: 'colorSwatches'
} as const;
