const featureName = 'language-and-currency';

export const LANGUAGE_AND_CURRENCY_NAMES = {
    feature: featureName,
    component: featureName,
    api: '',
    httpApi: '',
    service: '',
    store: ''
} as const;
