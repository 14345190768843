import {
    AxiosClient,
    CACHE_TYPES,
    DEFAULT_REQUESTER_CACHE_NAMESPACE,
    ExternalHTTPRequesterApi,
    FEATURE_CORES_TYPES,
    HTTPRequesterApi,
    HTTPRequesterBalancer,
    HTTP_REQUESTER_FEATURE_NAME,
    IRequestOptions,
    IRequesterFetchItem,
    ISanitizerApi,
    IStoreFactoryApi,
    REQUEST_SOURCE,
    Requester,
    RequesterCacheServiceKeySerializer,
    SANITIZER_API_NAME,
    STORE_FACTORY_API_NAME,
    StrategiesContainer,
    SyncFeatureInitializer,
    TFeatureRecord,
    CacheService,
    TToSanitize
} from '@dreamcommerce/star_core';

import { CookieCache } from '@storefrontCoreFeatures/http_requester/custom_caches/cookie_cache/cookie_cache';
import { AUTH_COOKIE_NAME } from '@storefrontRoot/features/authentication/authentication_constants';
import Cookies from 'js-cookie';

export class StorefrontHTTPRequesterInitializer extends SyncFeatureInitializer {
    public static featureName = HTTP_REQUESTER_FEATURE_NAME;

    public init(): TFeatureRecord {
        const sanitizerApi = {
            moduleName: 'sanitizeBlank',
            dispose: () => {},
            sanitize: (arg: TToSanitize) => arg
        };
        const httpClient = new AxiosClient();

        const requesters = new StrategiesContainer<IRequestOptions, IRequesterFetchItem<unknown>>();

        const cacheService = new CacheService(DEFAULT_REQUESTER_CACHE_NAMESPACE, {
            keySerializerResolver: new RequesterCacheServiceKeySerializer(),
            cacheType: CACHE_TYPES.memory,
            maxKeys: 100
        });

        /**
         * Example of cookie cache registration
         */
        cacheService.registerCustomCacheFactory('user', {
            createCache: () => {
                return new CookieCache({
                    cookieName: 'user',
                    cookies: Cookies,
                    storeFactoryApi: this.getApiSync<IStoreFactoryApi>(STORE_FACTORY_API_NAME)
                });
            }
        });

        cacheService.registerCustomCacheFactory(AUTH_COOKIE_NAME, {
            createCache: () => {
                return new CookieCache({
                    cookieName: AUTH_COOKIE_NAME,
                    cookies: Cookies,
                    storeFactoryApi: this.getApiSync<IStoreFactoryApi>(STORE_FACTORY_API_NAME)
                });
            }
        });

        // cacheService.registerCustomCacheFactory('basketIndex', {
        //     createCache: () => {
        //         return new CookieCache({
        //             cookieName: 'basketIndex',
        //             cookies: Cookies,
        //             storeFactoryApi: this.getApiSync<IStoreFactoryApi>(STORE_FACTORY_API_NAME)
        //         });
        //     }
        // });

        requesters.add(REQUEST_SOURCE.internal, new Requester({ httpClient, sanitizerApi, cacheService }));

        return {
            cores: [
                {
                    type: FEATURE_CORES_TYPES.service,
                    instance: new HTTPRequesterBalancer(requesters)
                },
                {
                    type: FEATURE_CORES_TYPES.api,
                    instance: [new HTTPRequesterApi(), new ExternalHTTPRequesterApi()]
                }
            ]
        };
    }
}
