import { immerable } from 'immer';
import { TCountryConstructorOptions } from '@storefrontRoot/models/country/country_types';

export class Country {
    [immerable] = true;

    public id: number;
    public name: string;
    public isocode: string;
    public locale?: string;

    private constructor({ country_id, isocode, name, locale }: TCountryConstructorOptions) {
        this.id = country_id;
        this.name = name;
        this.isocode = isocode;
        this.locale = locale;
    }

    public static create(options: TCountryConstructorOptions): Country {
        return new Country(options);
    }
}
