import {
    FEATURE_CORES_TYPES,
    FEATURE_SYSTEM_API_NAME,
    IFeatureSystemApi,
    SyncFeatureInitializer,
    TDynamicFeaturesResolverRecord,
    TFeatureRecord
} from '@dreamcommerce/star_core';

import { COMPONENTS_MANAGEMENT_NAME } from '@storefrontCoreFeatures/components/management/components_management_constants';
import { COMPONENT_CREATOR_TYPE } from '@storefrontCoreFeatures/components/management/creators/components_creator_constants';
import { ComponentsIntersectionObserver } from '@storefrontCoreFeatures/components/management/observers/intersection/components_intersection_observer';
import { ComponentsMutationObserver } from '@storefrontCoreFeatures/components/management/observers/components_mutation_observer';
import { ComponentsPageEventsObserver } from '@storefrontCoreFeatures/components/management/observers/components_page_events_observer';
import { ComponentsResolver } from '@storefrontCoreFeatures/components/management/resolver/components_resolver';
import { PageManagerLoadedHandler } from '@storefrontCoreFeatures/page_management/events/page_manager_loaded_handler';
import { PageManagerRenderedHandler } from '@storefrontCoreFeatures/page_management/events/page_manager_rendered_handler';
import { STOREFRONT_DYNAMIC_COMPONENTS_MAPPER } from '@storefrontCoreFeatures/components/management/storefront_dynamic_components_mapper';
import { WebComponentCreator } from '@storefrontCoreFeatures/components/management/creators/web_component_creator';
import { FeaturesRegistrationObserver } from '@storefrontCoreFeatures/components/management/observers/features_registration_observer';
import { ComponentsOnInteractionObserver } from '@storefrontCoreFeatures/components/management/observers/components_on_interaction_observer';
import { InpostPayObserver } from '@storefrontFeatures/inpost_pay/inpost_pay_observer';

export class StorefrontComponentsManagementInitializer extends SyncFeatureInitializer {
    public static featureName = COMPONENTS_MANAGEMENT_NAME;

    public init(): TFeatureRecord {
        const featureSystemApi = this.getApiSync(FEATURE_SYSTEM_API_NAME) as IFeatureSystemApi;
        const componentsResolver = new ComponentsResolver({
            globalCoresContainersRegistry: this.globalCoresContainersRegistry,
            globalInitializersContainer: this.globalInitializersContainer,
            featureSystemApi,
            componentCreators: {
                [COMPONENT_CREATOR_TYPE.webComponent]: new WebComponentCreator()
            },
            dynamicComponentsNames: this._getDynamicComponentsNames()
        });
        const pageEventsComponentsService = new ComponentsPageEventsObserver({ componentsResolver });

        return {
            cores: [
                {
                    type: FEATURE_CORES_TYPES.service,
                    instance: [
                        new ComponentsOnInteractionObserver({ componentsResolver }),
                        new ComponentsIntersectionObserver({ componentsResolver }),
                        new ComponentsMutationObserver({ componentsResolver }),
                        new FeaturesRegistrationObserver({ componentsResolver }),
                        new InpostPayObserver(featureSystemApi)
                    ]
                },
                {
                    type: FEATURE_CORES_TYPES.eventHandler,
                    instance: [
                        new PageManagerLoadedHandler(() => pageEventsComponentsService.initializeComponents),
                        new PageManagerRenderedHandler(pageEventsComponentsService.initializeComponents)
                    ]
                }
            ]
        };
    }

    private _getDynamicComponentsNames(): Record<string, boolean> {
        return STOREFRONT_DYNAMIC_COMPONENTS_MAPPER.reduce<Record<string, boolean>>((acc, { featureName }: TDynamicFeaturesResolverRecord) => {
            acc[featureName] = true;

            return acc;
        }, {});
    }
}
