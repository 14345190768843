import { FORM_CONTROLS_TYPES } from '@storefrontFeatures/form_management/state/models/form_control_constants';

export const FORM_MANAGEMENT_NAMES = {
    feature: 'form-connector',
    component: 'form-connector'
} as const;

export const FORM_CONTROLS_SELECTORS = {
    [FORM_CONTROLS_TYPES.text]: 'input[type=text]',
    [FORM_CONTROLS_TYPES.radio]: 'input[type=radio]',
    [FORM_CONTROLS_TYPES.checkbox]: 'input[type=checkbox]',
    [FORM_CONTROLS_TYPES.number]: 'input[type=number]',
    [FORM_CONTROLS_TYPES.range]: 'input[type=range]',
    [FORM_CONTROLS_TYPES.hidden]: 'input[type=hidden]',
    [FORM_CONTROLS_TYPES.email]: 'input[type=email]',
    [FORM_CONTROLS_TYPES.password]: 'input[type=password]',
    [FORM_CONTROLS_TYPES.tel]: 'input[type=tel]',
    [FORM_CONTROLS_TYPES.textarea]: 'textarea',
    [FORM_CONTROLS_TYPES.select]: 'select,h-select',
    [FORM_CONTROLS_TYPES.multiSelect]: 'select[multiple],h-select[multiple]',
    [FORM_CONTROLS_TYPES.file]: 'h-file-picker',
    [FORM_CONTROLS_TYPES.colorSwatches]: 'h-color-swatches-control'
} as const;
