import { IComponentsCreator, TCreatedComponentRecord } from '@storefrontCoreFeatures/components/management/creators/components_creator_types';

export class WebComponentCreator implements IComponentsCreator {
    public create({ componentClass, componentName }: TCreatedComponentRecord): void {
        if (window.customElements.get(componentName)) {
            return;
        }

        window.customElements.define(componentName, componentClass);
    }
}
