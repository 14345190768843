import {
    DEFAULT_FLASH_MESSAGE_TIMEOUT,
    DEFAULT_LONG_FLASH_MESSAGE_TIMEOUT,
    FLASH_MESSAGE_DEFAULT_ICON_NAMES,
    FLASH_MESSAGE_TYPES,
    MINIMUM_MESSAGE_TTL_IN_MS,
    SHORT_FLASH_MESSAGE_MAX_LENGTH
} from '@storefrontRoot/features/flash_messenger/state/models/flash_message_constants';
import {
    TFlashMessageConstructorOptions,
    TFlashMessageDTO,
    TFlashMessageIconName,
    TFlashMessageIconOptions,
    TFlashMessageTypeName
} from '@storefrontRoot/features/flash_messenger/state/models/flash_message_types';

import { immerable } from 'immer';
import { v4 as uuidv4 } from 'uuid';

export class FlashMessage {
    [immerable] = true;

    public content: string;
    public type: TFlashMessageTypeName;
    public icon: TFlashMessageIconOptions;
    public timeout: number;
    public isCloseable?: boolean;
    public id = uuidv4();

    private constructor({ content, type, isCloseable, timeout, icon }: TFlashMessageConstructorOptions) {
        this.content = content;
        this.type = type || FLASH_MESSAGE_TYPES.info;
        this.isCloseable = isCloseable === undefined ? true : isCloseable;
        this.timeout = this._calculateFlashMessageTimeout(content, timeout);

        this.icon = icon || this._setIcon(type || FLASH_MESSAGE_TYPES.info);
    }

    private _setIcon(type: TFlashMessageTypeName): TFlashMessageIconOptions {
        return {
            name: FLASH_MESSAGE_DEFAULT_ICON_NAMES[type]
        }
    }

    private _calculateFlashMessageTimeout(content: string, timeout?: number): number {
        if (timeout === 0) return timeout;

        if (timeout !== undefined) return timeout > MINIMUM_MESSAGE_TTL_IN_MS ? timeout : MINIMUM_MESSAGE_TTL_IN_MS;

        return content.length > SHORT_FLASH_MESSAGE_MAX_LENGTH ? DEFAULT_LONG_FLASH_MESSAGE_TIMEOUT : DEFAULT_FLASH_MESSAGE_TIMEOUT;
    }

    public static create(options: TFlashMessageDTO): FlashMessage {
        return new FlashMessage(options as TFlashMessageConstructorOptions);
    }
}
