const componentName = 'blog-article-comment-form';
const featureName = 'BlogArticleCommentForm';

export const BLOG_ARTICLE_COMMENT_FORM_NAMES = {
    feature: componentName,
    component: componentName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: ''
} as const;
