export const PRODUCT_VARIANTS_NAMES = {
    feature: 'product-variants'
} as const;

export const PRODUCT_VARIANTS_COMMAND_NAMES = {
    validateVariantsOptions: 'ProductVariants.validateVariantsOptions'
} as const;

export const PRODUCT_VARIANTS_CLASS_NAMES = {
    productVariantsError: 'product-variants_error'
} as const;

export const PRODUCT_VARIANT_OPTION_UNAVAILABLE_ATTRIBUTE_NAME = 'data-option-value-unavailable';

export const PRODUCT_STOCK_OPTION_ATTRIBUTE_NAME = 'is-stock';

export const PRODUCT_VARIANT_FLASH_MESSENGER_CONTAINER_NAME = 'product-variants-flash-messenger-container';

export const PRODUCT_VARIANT_VALIDATION_INFO_BOX_CLASS_NAME = 'js__variant-validation-info-box';
