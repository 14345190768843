const componentName = 'contact-form';
const featureName = 'ContactForm';

export const CONTACT_FORM_NAMES = {
    feature: componentName,
    component: componentName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;
