const featureName = 'Translations';

export const TRANSLATION_NAMES = {
    feature: featureName,
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;

export const CONSTANTS_I18N = {
    LANGUAGE_CHANGED: 'languageChanged'
} as const;
