const featureName = 'BuyButton';

export const BUY_BUTTON_NAMES = {
    feature: featureName,
    component: `buy-button`
} as const;

export const BUY_BUTTON_CLASSNAMES = {
    buyButtonWrapper: 'product-actions__wrapper'
} as const;
