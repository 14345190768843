import { COMPONENTS_ON_INTERACTION_OBSERVER } from '@storefrontCoreFeatures/components/management/components_management_constants';
import { IComponentsResolver } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_types';
import { ComponentsIntersectionObserverConstructorOptions } from '@storefrontCoreFeatures/components/management/observers/intersection/components_intersection_observer_types';
import { FeatureCore } from '@dreamcommerce/star_core';
import { ON_INTERACTION_COMPONENT_ATTRIBUTE_NAME } from '@storefrontCoreFeatures/components/management/observers/components_observers_constants';
import {PAGE_MANAGER_EVENTS} from "@storefrontCoreFeatures/page_management/turbo_message_names";

export class ComponentsOnInteractionObserver extends FeatureCore {
    public moduleName = COMPONENTS_ON_INTERACTION_OBSERVER;

    #interactionsEvents = ['keydown', 'mousemove', 'touchstart'];

    #componentsResolver: IComponentsResolver;

    constructor({ componentsResolver }: ComponentsIntersectionObserverConstructorOptions) {
        super();

        this.#componentsResolver = componentsResolver;

        this._setupInteractionEvents();
        this.eventBus.on(PAGE_MANAGER_EVENTS.rendered, () => {
            this._setupInteractionEvents();
        });
    }

    private _setupInteractionEvents(): void {
        this.#interactionsEvents.forEach((eventName: string) => document.addEventListener(eventName, this._initComponents));
    }

    private _initComponents = (): void => {
        this._detachAllEvents();

        const componentsToInitialize = [...document.querySelectorAll<HTMLElement>(`[${ON_INTERACTION_COMPONENT_ATTRIBUTE_NAME}]`)];

        this.#componentsResolver.resolve(componentsToInitialize);
    };

    private _detachAllEvents(): void {
        this.#interactionsEvents.forEach((eventName: string) => document.removeEventListener(eventName, this._initComponents));
    }
}
