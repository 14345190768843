export const PRODUCT_DESCRIPTION_MODULE_NAME = 'product-description';

const featureName = 'product-description';

export const PRODUCT_DESCRIPTION_NAMES = {
    feature: featureName,
    component: featureName,
    api: '',
    service: '',
    store: ''
};
