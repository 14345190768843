import { EventMessage, FeatureApi } from '@dreamcommerce/star_core';
import {
    COOKIE_FLASH_MESSENGER_NAME,
    FLASH_MESSENGER_NAMES,
    GLOBAL_FLASH_MESSENGER_CONTAINER_NAME
} from '@storefrontRoot/features/flash_messenger/flash_messenger_constants';
import {
    TFlashMessageDTO,
    TFlashMessageOptions,
    TFlashMessageTypeName
} from '@storefrontRoot/features/flash_messenger/state/models/flash_message_types';

import { FLASH_MESSAGE_TYPES } from '@storefrontRoot/features/flash_messenger/state/models/flash_message_constants';
import { FLASH_MESSENGER_EVENTS } from '@storefrontRoot/features/flash_messenger/flash_messenger_events';
import { FlashMessage } from '@storefrontRoot/features/flash_messenger/state/models/flash_message';
import { IFlashMessengerApi } from '@storefrontRoot/features/flash_messenger/api/flash_messenger_api_types';
import { TFlashMessenger } from '@storefrontRoot/app/base_http_api/base_http_api_types';
import Cookie from 'js-cookie';

export class FlashMessengerApi extends FeatureApi implements IFlashMessengerApi {
    readonly moduleName = FLASH_MESSENGER_NAMES.api;

    constructor() {
        super();

        this._showFlashMessagesFromCookie();
    }

    private _showFlashMessagesFromCookie(): void {
        this.parseAndAddFlashMessages(JSON.parse(Cookie.get(COOKIE_FLASH_MESSENGER_NAME) || '{}'));
        Cookie.remove(COOKIE_FLASH_MESSENGER_NAME);
    }

    public addFlashMessage(flashMessageOptions: TFlashMessageOptions, containerName = GLOBAL_FLASH_MESSENGER_CONTAINER_NAME): string {
        const flashMessage = FlashMessage.create({
            ...flashMessageOptions,
            type: flashMessageOptions.type || FLASH_MESSAGE_TYPES.info
        });

        this.eventBus.emit(
            new EventMessage(FLASH_MESSENGER_EVENTS.addFlashMessage, {
                containerName,
                flashMessage
            })
        );

        return flashMessage.id;
    }

    public addFlashMessages(flashMessagesOptions: TFlashMessageOptions[], containerName = GLOBAL_FLASH_MESSENGER_CONTAINER_NAME): string[] {
        const flashMessages = flashMessagesOptions.map((flashMessageOptions) =>
            FlashMessage.create({
                ...flashMessageOptions,
                type: flashMessageOptions.type || FLASH_MESSAGE_TYPES.info
            })
        );

        this.eventBus.emit(
            new EventMessage(FLASH_MESSENGER_EVENTS.addFlashMessages, {
                containerName,
                flashMessages
            })
        );

        return flashMessages.map((message) => message.id);
    }

    public removeFlashMessage(id: string, containerName = GLOBAL_FLASH_MESSENGER_CONTAINER_NAME): void {
        this.eventBus.emit(
            new EventMessage(FLASH_MESSENGER_EVENTS.removeFlashMessage, {
                containerName,
                id
            })
        );
    }

    public removeAllFlashMessages(containerName = GLOBAL_FLASH_MESSENGER_CONTAINER_NAME): void {
        this.eventBus.emit(
            new EventMessage(FLASH_MESSENGER_EVENTS.removeAllFlashMessages, {
                containerName
            })
        );
    }

    public parseAndAddFlashMessages(flashMessages: TFlashMessenger, containerName = GLOBAL_FLASH_MESSENGER_CONTAINER_NAME): void {
        if (!flashMessages) return;

        const parsedMessages = Object.entries(flashMessages).reduce<TFlashMessageDTO[]>((allMessages, [type, messages]) => {
            const messagesOfCurrentType = messages.map((message) => ({
                content: message,
                type: type as TFlashMessageTypeName
            }));
            return [...allMessages, ...messagesOfCurrentType];
        }, []);

        this.addFlashMessages(parsedMessages, containerName);
    }
}
