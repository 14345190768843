import { FeatureCore } from '@dreamcommerce/star_core';
import { JsonUtils } from '@dreamcommerce/utilities';
import { TStorefrontSettingsStore } from '@storefrontFeatures/storefront_settings/state/storefront_settings_types';
import { select, setProps } from '@ngneat/elf';
import {
    INPOST_PAY_WIDGET_SETTINGS_KEY,
    PAGE_SETTINGS_KEY,
    SKIN_SETTINGS_KEY,
    STOREFRONT_SETTINGS_NAMES
} from '@storefrontRoot/features/storefront_settings/storefront_settings_constants';
import { IStorefrontSettingsService } from '@storefrontRoot/features/storefront_settings/storefront_settings_types';
import { Country } from '@storefrontRoot/models/country/country';
import { Observable } from 'rxjs';

export class StorefrontSettingsService extends FeatureCore implements IStorefrontSettingsService {
    public moduleName = STOREFRONT_SETTINGS_NAMES.service;

    readonly #store: TStorefrontSettingsStore;

    readonly #settingsKeys = [SKIN_SETTINGS_KEY, PAGE_SETTINGS_KEY, INPOST_PAY_WIDGET_SETTINGS_KEY];

    constructor(store: TStorefrontSettingsStore) {
        super();

        this.#store = store;

        this.updateSettingsFromPageData();
    }

    public getCountries(): Country[] {
        return this.#store.getValue().countries;
    }

    public selectCountries$(): Observable<Country[]> {
        return this.#store.pipe(select((state) => state['countries']));
    }

    public getCountryById(id: number): Country | null {
        return this.#store.getValue().countries.find((country) => country.id === id) || null;
    }

    public selectCountryById$(id: number): Observable<Country | null> {
        return this.#store.pipe(
            select((state) => state['countries']),
            select((countries) => countries.find((country) => country.id === id) || null)
        );
    }

    public getCountryByCode(code: string): Country | null {
        return this.#store.getValue().countries.find((country) => country.isocode === code) || null;
    }

    public selectCountryByCode$(code: string): Observable<Country | null> {
        return this.#store.pipe(
            select((state) => state['countries']),
            select((countries) => countries.find((country) => country.isocode === code) || null)
        );
    }

    public updateSettingsFromPageData = (): void => {
        const settings = this.#settingsKeys.reduce((currSettings, id) => {
            const $script = document.getElementById(id);
            if ($script) {
                const settings = this.#toObjectFromJsonScriptTag($script);

                return {
                    ...currSettings,
                    [id]: settings
                };
            }

            return currSettings;
        }, {});

        this.#store.update(setProps(settings));
    };

    #toObjectFromJsonScriptTag = (scriptTag: HTMLElement): Record<string, string> => {
        const scriptContent = scriptTag.textContent as string;
        return JsonUtils.fromJson<Record<string, string>>(scriptContent) || {};
    };
}
