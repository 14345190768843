export const COMPONENTS_CONTAINER_NAME = 'component';

export const COMPONENTS_RESOLVER_NAME = 'ComponentsResolver';

export const COMPONENTS_MANAGEMENT_NAME = 'StorefrontComponentsManagement';

export const COMPONENTS_PAGE_EVENTS_OBSERVER = 'ComponentsPageEventsObserver';

export const COMPONENTS_ON_INTERACTION_OBSERVER = 'ComponentsOnInteractionObserver';

export const COMPONENTS_INTERSECTION_OBSERVER_NAME = 'ComponentsIntersectionObserver';

export const COMPONENTS_MUTATION_OBSERVER_NAME = 'ComponentsMutationObserver';

export const FEATURES_REGISTRATION_OBSERVER_NAME = 'FeaturesRegistrationObserver';
