export const countries = [
    {
        country_id: 1,
        isocode: 'AD',
        name: 'Andorra',
        locale: 'ca_AD'
    },
    {
        country_id: 2,
        isocode: 'AE',
        name: 'United Arab Emirates',
        locale: 'ar_AE'
    },
    {
        country_id: 3,
        isocode: 'AF',
        name: 'Afghanistan',
        locale: 'fa_AF'
    },
    {
        country_id: 4,
        isocode: 'AG',
        name: 'Antigua and Barbuda',
        locale: 'en_AG'
    },
    {
        country_id: 5,
        isocode: 'AI',
        name: 'Anguilla',
        locale: 'en_AI'
    },
    {
        country_id: 6,
        isocode: 'AL',
        name: 'Albania',
        locale: 'sq_AL'
    },
    {
        country_id: 7,
        isocode: 'AM',
        name: 'Armenia',
        locale: 'hy_AM'
    },
    {
        country_id: 8,
        isocode: 'AO',
        name: 'Angola',
        locale: 'pt_AO'
    },
    {
        country_id: 9,
        isocode: 'AQ',
        name: 'Antarctica',
        locale: 'en_AQ'
    },
    {
        country_id: 10,
        isocode: 'AR',
        name: 'Argentina',
        locale: 'es_AR'
    },
    {
        country_id: 11,
        isocode: 'AS',
        name: 'American Samoa',
        locale: 'en_AS'
    },
    {
        country_id: 12,
        isocode: 'AT',
        name: 'Austria',
        locale: 'de_AT'
    },
    {
        country_id: 13,
        isocode: 'AU',
        name: 'Australia',
        locale: 'en_AU'
    },
    {
        country_id: 14,
        isocode: 'AW',
        name: 'Aruba',
        locale: 'nl_AW'
    },
    {
        country_id: 15,
        isocode: 'AX',
        name: 'Åland Islands',
        locale: 'sv_AX'
    },
    {
        country_id: 16,
        isocode: 'AZ',
        name: 'Azerbaijan',
        locale: 'az_AZ'
    },
    {
        country_id: 17,
        isocode: 'BA',
        name: 'Bosnia and Herzegovina',
        locale: 'bs_BA'
    },
    {
        country_id: 18,
        isocode: 'BB',
        name: 'Barbados',
        locale: 'en_BB'
    },
    {
        country_id: 19,
        isocode: 'BD',
        name: 'Bangladesh',
        locale: 'bn_BD'
    },
    {
        country_id: 20,
        isocode: 'BE',
        name: 'Belgium',
        locale: 'nl_BE'
    },
    {
        country_id: 21,
        isocode: 'BF',
        name: 'Burkina Faso',
        locale: 'fr_BF'
    },
    {
        country_id: 22,
        isocode: 'BG',
        name: 'Bulgaria',
        locale: 'bg_BG'
    },
    {
        country_id: 23,
        isocode: 'BH',
        name: 'Bahrain',
        locale: 'ar_BH'
    },
    {
        country_id: 24,
        isocode: 'BI',
        name: 'Burundi',
        locale: 'fr_BI'
    },
    {
        country_id: 25,
        isocode: 'BJ',
        name: 'Benin',
        locale: 'fr_BJ'
    },
    {
        country_id: 26,
        isocode: 'BL',
        name: 'Saint Barthélemy',
        locale: 'fr_BL'
    },
    {
        country_id: 27,
        isocode: 'BM',
        name: 'Bermuda',
        locale: 'en_BM'
    },
    {
        country_id: 28,
        isocode: 'BN',
        name: 'Brunei',
        locale: 'ms_BN'
    },
    {
        country_id: 29,
        isocode: 'BO',
        name: 'Bolivia',
        locale: 'es_BO'
    },
    {
        country_id: 30,
        isocode: 'BQ',
        name: 'Caribbean Netherlands',
        locale: 'nl_BQ'
    },
    {
        country_id: 31,
        isocode: 'BR',
        name: 'Brazil',
        locale: 'pt_BR'
    },
    {
        country_id: 32,
        isocode: 'BS',
        name: 'Bahamas',
        locale: 'en_BS'
    },
    {
        country_id: 33,
        isocode: 'BT',
        name: 'Bhutan',
        locale: 'dz_BT'
    },
    {
        country_id: 34,
        isocode: 'BV',
        name: 'Bouvet Island',
        locale: 'en_BV'
    },
    {
        country_id: 35,
        isocode: 'BW',
        name: 'Botswana',
        locale: 'en_BW'
    },
    {
        country_id: 36,
        isocode: 'BY',
        name: 'Belarus',
        locale: 'be_BY'
    },
    {
        country_id: 37,
        isocode: 'BZ',
        name: 'Belize',
        locale: 'en_BZ'
    },
    {
        country_id: 38,
        isocode: 'CA',
        name: 'Canada',
        locale: 'en_CA'
    },
    {
        country_id: 39,
        isocode: 'CC',
        name: 'Cocos (Keeling) Islands',
        locale: 'en_CC'
    },
    {
        country_id: 40,
        isocode: 'CD',
        name: 'Congo - Kinshasa',
        locale: 'fr_CD'
    },
    {
        country_id: 41,
        isocode: 'CF',
        name: 'Central African Republic',
        locale: 'fr_CF'
    },
    {
        country_id: 42,
        isocode: 'CG',
        name: 'Congo - Brazzaville',
        locale: 'fr_CG'
    },
    {
        country_id: 43,
        isocode: 'CH',
        name: 'Switzerland',
        locale: 'de_CH'
    },
    {
        country_id: 44,
        isocode: 'CI',
        name: 'Côte d’Ivoire',
        locale: 'fr_CI'
    },
    {
        country_id: 45,
        isocode: 'CK',
        name: 'Cook Islands',
        locale: 'en_CK'
    },
    {
        country_id: 46,
        isocode: 'CL',
        name: 'Chile',
        locale: 'es_CL'
    },
    {
        country_id: 47,
        isocode: 'CM',
        name: 'Cameroon',
        locale: 'fr_CM'
    },
    {
        country_id: 48,
        isocode: 'CN',
        name: 'China',
        locale: 'zh_CN'
    },
    {
        country_id: 49,
        isocode: 'CO',
        name: 'Colombia',
        locale: 'es_CO'
    },
    {
        country_id: 50,
        isocode: 'CR',
        name: 'Costa Rica',
        locale: 'es_CR'
    },
    {
        country_id: 51,
        isocode: 'CU',
        name: 'Cuba',
        locale: 'es_CU'
    },
    {
        country_id: 52,
        isocode: 'CV',
        name: 'Cape Verde',
        locale: 'pt_CV'
    },
    {
        country_id: 53,
        isocode: 'CW',
        name: 'Curaçao',
        locale: 'nl_CW'
    },
    {
        country_id: 54,
        isocode: 'CX',
        name: 'Christmas Island',
        locale: 'en_CX'
    },
    {
        country_id: 55,
        isocode: 'CY',
        name: 'Cyprus',
        locale: 'el_CY'
    },
    {
        country_id: 56,
        isocode: 'CZ',
        name: 'Czech Republic',
        locale: 'cs_CZ'
    },
    {
        country_id: 57,
        isocode: 'DE',
        name: 'Germany',
        locale: 'de_DE'
    },
    {
        country_id: 58,
        isocode: 'DJ',
        name: 'Djibouti',
        locale: 'fr_DJ'
    },
    {
        country_id: 59,
        isocode: 'DK',
        name: 'Denmark',
        locale: 'da_DK'
    },
    {
        country_id: 60,
        isocode: 'DM',
        name: 'Dominica',
        locale: 'en_DM'
    },
    {
        country_id: 61,
        isocode: 'DO',
        name: 'Dominican Republic',
        locale: 'es_DO'
    },
    {
        country_id: 62,
        isocode: 'DZ',
        name: 'Algeria',
        locale: 'ar_DZ'
    },
    {
        country_id: 63,
        isocode: 'EC',
        name: 'Ecuador',
        locale: 'es_EC'
    },
    {
        country_id: 64,
        isocode: 'EE',
        name: 'Estonia',
        locale: 'et_EE'
    },
    {
        country_id: 65,
        isocode: 'EG',
        name: 'Egypt',
        locale: 'ar_EG'
    },
    {
        country_id: 66,
        isocode: 'EH',
        name: 'Western Sahara',
        locale: 'ar_EH'
    },
    {
        country_id: 67,
        isocode: 'ER',
        name: 'Eritrea',
        locale: 'ti_ER'
    },
    {
        country_id: 68,
        isocode: 'ES',
        name: 'Spain',
        locale: 'es_ES'
    },
    {
        country_id: 69,
        isocode: 'ET',
        name: 'Ethiopia',
        locale: 'am_ET'
    },
    {
        country_id: 70,
        isocode: 'FI',
        name: 'Finland',
        locale: 'fi_FI'
    },
    {
        country_id: 71,
        isocode: 'FJ',
        name: 'Fiji',
        locale: 'en_FJ'
    },
    {
        country_id: 72,
        isocode: 'FK',
        name: 'Falkland Islands',
        locale: 'en_FK'
    },
    {
        country_id: 73,
        isocode: 'FM',
        name: 'Micronesia',
        locale: 'en_FM'
    },
    {
        country_id: 74,
        isocode: 'FO',
        name: 'Faroe Islands',
        locale: 'fo_FO'
    },
    {
        country_id: 75,
        isocode: 'FR',
        name: 'France',
        locale: 'fr_FR'
    },
    {
        country_id: 76,
        isocode: 'GA',
        name: 'Gabon',
        locale: 'fr_GA'
    },
    {
        country_id: 77,
        isocode: 'GB',
        name: 'United Kingdom',
        locale: 'en_GB'
    },
    {
        country_id: 78,
        isocode: 'GD',
        name: 'Grenada',
        locale: 'en_GD'
    },
    {
        country_id: 79,
        isocode: 'GE',
        name: 'Georgia',
        locale: 'ka_GE'
    },
    {
        country_id: 80,
        isocode: 'GF',
        name: 'French Guiana',
        locale: 'fr_GF'
    },
    {
        country_id: 81,
        isocode: 'GG',
        name: 'Guernsey',
        locale: 'en_GG'
    },
    {
        country_id: 82,
        isocode: 'GH',
        name: 'Ghana',
        locale: 'en_GH'
    },
    {
        country_id: 83,
        isocode: 'GI',
        name: 'Gibraltar',
        locale: 'en_GI'
    },
    {
        country_id: 84,
        isocode: 'GL',
        name: 'Greenland',
        locale: 'kl_GL'
    },
    {
        country_id: 85,
        isocode: 'GM',
        name: 'Gambia',
        locale: 'en_GM'
    },
    {
        country_id: 86,
        isocode: 'GN',
        name: 'Guinea',
        locale: 'fr_GN'
    },
    {
        country_id: 87,
        isocode: 'GP',
        name: 'Guadeloupe',
        locale: 'fr_GP'
    },
    {
        country_id: 88,
        isocode: 'GQ',
        name: 'Equatorial Guinea',
        locale: 'es_GQ'
    },
    {
        country_id: 89,
        isocode: 'GR',
        name: 'Greece',
        locale: 'el_GR'
    },
    {
        country_id: 90,
        isocode: 'GS',
        name: 'South Georgia & South Sandwich Islands',
        locale: 'en_GS'
    },
    {
        country_id: 91,
        isocode: 'GT',
        name: 'Guatemala',
        locale: 'es_GT'
    },
    {
        country_id: 92,
        isocode: 'GU',
        name: 'Guam',
        locale: 'en_GU'
    },
    {
        country_id: 93,
        isocode: 'GW',
        name: 'Guinea-Bissau',
        locale: 'pt_GW'
    },
    {
        country_id: 94,
        isocode: 'GY',
        name: 'Guyana',
        locale: 'en_GY'
    },
    {
        country_id: 95,
        isocode: 'HK',
        name: 'Hong Kong SAR China',
        locale: 'zh_HK'
    },
    {
        country_id: 96,
        isocode: 'HM',
        name: 'Heard & McDonald Islands',
        locale: 'en_HM'
    },
    {
        country_id: 97,
        isocode: 'HN',
        name: 'Honduras',
        locale: 'es_HN'
    },
    {
        country_id: 98,
        isocode: 'HR',
        name: 'Croatia',
        locale: 'hr_HR'
    },
    {
        country_id: 99,
        isocode: 'HT',
        name: 'Haiti',
        locale: 'fr_HT'
    },
    {
        country_id: 100,
        isocode: 'HU',
        name: 'Hungary',
        locale: 'hu_HU'
    },
    {
        country_id: 101,
        isocode: 'ID',
        name: 'Indonesia',
        locale: 'id_ID'
    },
    {
        country_id: 102,
        isocode: 'IE',
        name: 'Ireland',
        locale: 'en_IE'
    },
    {
        country_id: 103,
        isocode: 'IL',
        name: 'Israel',
        locale: 'he_IL'
    },
    {
        country_id: 104,
        isocode: 'IM',
        name: 'Isle of Man',
        locale: 'en_IM'
    },
    {
        country_id: 105,
        isocode: 'IN',
        name: 'India',
        locale: 'hi_IN'
    },
    {
        country_id: 106,
        isocode: 'IO',
        name: 'British Indian Ocean Territory',
        locale: 'en_IO'
    },
    {
        country_id: 107,
        isocode: 'IQ',
        name: 'Iraq',
        locale: 'ar_IQ'
    },
    {
        country_id: 108,
        isocode: 'IR',
        name: 'Iran',
        locale: 'fa_IR'
    },
    {
        country_id: 109,
        isocode: 'IS',
        name: 'Iceland',
        locale: 'is_IS'
    },
    {
        country_id: 110,
        isocode: 'IT',
        name: 'Italy',
        locale: 'it_IT'
    },
    {
        country_id: 111,
        isocode: 'JE',
        name: 'Jersey',
        locale: 'en_JE'
    },
    {
        country_id: 112,
        isocode: 'JM',
        name: 'Jamaica',
        locale: 'en_JM'
    },
    {
        country_id: 113,
        isocode: 'JO',
        name: 'Jordan',
        locale: 'ar_JO'
    },
    {
        country_id: 114,
        isocode: 'JP',
        name: 'Japan',
        locale: 'ja_JP'
    },
    {
        country_id: 115,
        isocode: 'KE',
        name: 'Kenya',
        locale: 'sw_KE'
    },
    {
        country_id: 116,
        isocode: 'KG',
        name: 'Kyrgyzstan',
        locale: 'ky_KG'
    },
    {
        country_id: 117,
        isocode: 'KH',
        name: 'Cambodia',
        locale: 'km_KH'
    },
    {
        country_id: 118,
        isocode: 'KI',
        name: 'Kiribati',
        locale: 'en_KI'
    },
    {
        country_id: 119,
        isocode: 'KM',
        name: 'Comoros',
        locale: 'ar_KM'
    },
    {
        country_id: 120,
        isocode: 'KN',
        name: 'Saint Kitts and Nevis',
        locale: 'en_KN'
    },
    {
        country_id: 121,
        isocode: 'KP',
        name: 'North Korea',
        locale: 'ko_KP'
    },
    {
        country_id: 122,
        isocode: 'KR',
        name: 'South Korea',
        locale: 'ko_KR'
    },
    {
        country_id: 123,
        isocode: 'KW',
        name: 'Kuwait',
        locale: 'ar_KW'
    },
    {
        country_id: 124,
        isocode: 'KY',
        name: 'Cayman Islands',
        locale: 'en_KY'
    },
    {
        country_id: 125,
        isocode: 'KZ',
        name: 'Kazakhstan',
        locale: 'kk_KZ'
    },
    {
        country_id: 126,
        isocode: 'LA',
        name: 'Laos',
        locale: 'lo_LA'
    },
    {
        country_id: 127,
        isocode: 'LB',
        name: 'Lebanon',
        locale: 'ar_LB'
    },
    {
        country_id: 128,
        isocode: 'LC',
        name: 'Saint Lucia',
        locale: 'en_LC'
    },
    {
        country_id: 129,
        isocode: 'LI',
        name: 'Liechtenstein',
        locale: 'de_LI'
    },
    {
        country_id: 130,
        isocode: 'LK',
        name: 'Sri Lanka',
        locale: 'si_LK'
    },
    {
        country_id: 131,
        isocode: 'LR',
        name: 'Liberia',
        locale: 'en_LR'
    },
    {
        country_id: 132,
        isocode: 'LS',
        name: 'Lesotho',
        locale: 'en_LS'
    },
    {
        country_id: 133,
        isocode: 'LT',
        name: 'Lithuania',
        locale: 'lt_LT'
    },
    {
        country_id: 134,
        isocode: 'LU',
        name: 'Luxembourg',
        locale: 'fr_LU'
    },
    {
        country_id: 135,
        isocode: 'LV',
        name: 'Latvia',
        locale: 'lv_LV'
    },
    {
        country_id: 136,
        isocode: 'LY',
        name: 'Libya',
        locale: 'ar_LY'
    },
    {
        country_id: 137,
        isocode: 'MA',
        name: 'Morocco',
        locale: 'ar_MA'
    },
    {
        country_id: 138,
        isocode: 'MC',
        name: 'Monaco',
        locale: 'fr_MC'
    },
    {
        country_id: 139,
        isocode: 'MD',
        name: 'Moldova',
        locale: 'ro_MD'
    },
    {
        country_id: 140,
        isocode: 'ME',
        name: 'Montenegro',
        locale: 'sr_ME'
    },
    {
        country_id: 141,
        isocode: 'MF',
        name: 'Saint Martin',
        locale: 'fr_MF'
    },
    {
        country_id: 142,
        isocode: 'MG',
        name: 'Madagascar',
        locale: 'mg_MG'
    },
    {
        country_id: 143,
        isocode: 'MH',
        name: 'Marshall Islands',
        locale: 'en_MH'
    },
    {
        country_id: 144,
        isocode: 'MK',
        name: 'Macedonia',
        locale: 'mk_MK'
    },
    {
        country_id: 145,
        isocode: 'ML',
        name: 'Mali',
        locale: 'fr_ML'
    },
    {
        country_id: 146,
        isocode: 'MM',
        name: 'Myanmar (Burma)',
        locale: 'my_MM'
    },
    {
        country_id: 147,
        isocode: 'MN',
        name: 'Mongolia',
        locale: 'mn_MN'
    },
    {
        country_id: 148,
        isocode: 'MO',
        name: 'Macau SAR China',
        locale: 'zh_MO'
    },
    {
        country_id: 149,
        isocode: 'MP',
        name: 'Northern Mariana Islands',
        locale: 'en_MP'
    },
    {
        country_id: 150,
        isocode: 'MQ',
        name: 'Martinique',
        locale: 'fr_MQ'
    },
    {
        country_id: 151,
        isocode: 'MR',
        name: 'Mauritania',
        locale: 'ar_MR'
    },
    {
        country_id: 152,
        isocode: 'MS',
        name: 'Montserrat',
        locale: 'en_MS'
    },
    {
        country_id: 153,
        isocode: 'MT',
        name: 'Malta',
        locale: 'mt_MT'
    },
    {
        country_id: 154,
        isocode: 'MU',
        name: 'Mauritius',
        locale: 'en_MU'
    },
    {
        country_id: 155,
        isocode: 'MV',
        name: 'Maldives',
        locale: 'dv_MV'
    },
    {
        country_id: 156,
        isocode: 'MW',
        name: 'Malawi',
        locale: 'en_MW'
    },
    {
        country_id: 157,
        isocode: 'MX',
        name: 'Mexico',
        locale: 'es_MX'
    },
    {
        country_id: 158,
        isocode: 'MY',
        name: 'Malaysia',
        locale: 'ms_MY'
    },
    {
        country_id: 159,
        isocode: 'MZ',
        name: 'Mozambique',
        locale: 'pt_MZ'
    },
    {
        country_id: 160,
        isocode: 'NA',
        name: 'Namibia',
        locale: 'en_NA'
    },
    {
        country_id: 161,
        isocode: 'NC',
        name: 'New Caledonia',
        locale: 'fr_NC'
    },
    {
        country_id: 162,
        isocode: 'NE',
        name: 'Niger',
        locale: 'fr_NE'
    },
    {
        country_id: 163,
        isocode: 'NF',
        name: 'Norfolk Island',
        locale: 'en_NF'
    },
    {
        country_id: 164,
        isocode: 'NG',
        name: 'Nigeria',
        locale: 'en_NG'
    },
    {
        country_id: 165,
        isocode: 'NI',
        name: 'Nicaragua',
        locale: 'es_NI'
    },
    {
        country_id: 166,
        isocode: 'NL',
        name: 'Netherlands',
        locale: 'nl_NL'
    },
    {
        country_id: 167,
        isocode: 'NO',
        name: 'Norway',
        locale: 'nb_NO'
    },
    {
        country_id: 168,
        isocode: 'NP',
        name: 'Nepal',
        locale: 'ne_NP'
    },
    {
        country_id: 169,
        isocode: 'NR',
        name: 'Nauru',
        locale: 'en_NR'
    },
    {
        country_id: 170,
        isocode: 'NU',
        name: 'Niue',
        locale: 'en_NU'
    },
    {
        country_id: 171,
        isocode: 'NZ',
        name: 'New Zealand',
        locale: 'en_NZ'
    },
    {
        country_id: 172,
        isocode: 'OM',
        name: 'Oman',
        locale: 'ar_OM'
    },
    {
        country_id: 173,
        isocode: 'PA',
        name: 'Panama',
        locale: 'es_PA'
    },
    {
        country_id: 174,
        isocode: 'PE',
        name: 'Peru',
        locale: 'es_PE'
    },
    {
        country_id: 175,
        isocode: 'PF',
        name: 'French Polynesia',
        locale: 'fr_PF'
    },
    {
        country_id: 176,
        isocode: 'PG',
        name: 'Papua New Guinea',
        locale: 'en_PG'
    },
    {
        country_id: 177,
        isocode: 'PH',
        name: 'Philippines',
        locale: 'en_PH'
    },
    {
        country_id: 178,
        isocode: 'PK',
        name: 'Pakistan',
        locale: 'ur_PK'
    },
    {
        country_id: 179,
        isocode: 'PL',
        name: 'Poland',
        locale: 'pl_PL'
    },
    {
        country_id: 180,
        isocode: 'PM',
        name: 'Saint Pierre and Miquelon',
        locale: 'fr_PM'
    },
    {
        country_id: 181,
        isocode: 'PN',
        name: 'Pitcairn Islands',
        locale: 'en_PN'
    },
    {
        country_id: 182,
        isocode: 'PR',
        name: 'Puerto Rico',
        locale: 'es_PR'
    },
    {
        country_id: 183,
        isocode: 'PS',
        name: 'Palestinian Territories',
        locale: 'ar_PS'
    },
    {
        country_id: 184,
        isocode: 'PT',
        name: 'Portugal',
        locale: 'pt_PT'
    },
    {
        country_id: 185,
        isocode: 'PW',
        name: 'Palau',
        locale: 'en_PW'
    },
    {
        country_id: 186,
        isocode: 'PY',
        name: 'Paraguay',
        locale: 'es_PY'
    },
    {
        country_id: 187,
        isocode: 'QA',
        name: 'Qatar',
        locale: 'ar_QA'
    },
    {
        country_id: 188,
        isocode: 'RE',
        name: 'Réunion',
        locale: 'fr_RE'
    },
    {
        country_id: 189,
        isocode: 'RO',
        name: 'Romania',
        locale: 'ro_RO'
    },
    {
        country_id: 190,
        isocode: 'RS',
        name: 'Serbia',
        locale: 'sr_RS'
    },
    {
        country_id: 191,
        isocode: 'RU',
        name: 'Russia',
        locale: 'ru_RU'
    },
    {
        country_id: 192,
        isocode: 'RW',
        name: 'Rwanda',
        locale: 'rw_RW'
    },
    {
        country_id: 193,
        isocode: 'SA',
        name: 'Saudi Arabia',
        locale: 'ar_SA'
    },
    {
        country_id: 194,
        isocode: 'SB',
        name: 'Solomon Islands',
        locale: 'en_SB'
    },
    {
        country_id: 195,
        isocode: 'SC',
        name: 'Seychelles',
        locale: 'fr_SC'
    },
    {
        country_id: 196,
        isocode: 'SD',
        name: 'Sudan',
        locale: 'ar_SD'
    },
    {
        country_id: 197,
        isocode: 'SE',
        name: 'Sweden',
        locale: 'sv_SE'
    },
    {
        country_id: 198,
        isocode: 'SG',
        name: 'Singapore',
        locale: 'en_SG'
    },
    {
        country_id: 199,
        isocode: 'SH',
        name: 'Saint Helena',
        locale: 'en_SH'
    },
    {
        country_id: 200,
        isocode: 'SI',
        name: 'Slovenia',
        locale: 'sl_SI'
    },
    {
        country_id: 201,
        isocode: 'SJ',
        name: 'Svalbard and Jan Mayen',
        locale: 'nb_SJ'
    },
    {
        country_id: 202,
        isocode: 'SK',
        name: 'Slovakia',
        locale: 'sk_SK'
    },
    {
        country_id: 203,
        isocode: 'SL',
        name: 'Sierra Leone',
        locale: 'en_SL'
    },
    {
        country_id: 204,
        isocode: 'SM',
        name: 'San Marino',
        locale: 'it_SM'
    },
    {
        country_id: 205,
        isocode: 'SN',
        name: 'Senegal',
        locale: 'fr_SN'
    },
    {
        country_id: 206,
        isocode: 'SO',
        name: 'Somalia',
        locale: 'so_SO'
    },
    {
        country_id: 207,
        isocode: 'SR',
        name: 'Suriname',
        locale: 'nl_SR'
    },
    {
        country_id: 208,
        isocode: 'SS',
        name: 'South Sudan',
        locale: 'en_SS'
    },
    {
        country_id: 209,
        isocode: 'ST',
        name: 'São Tomé and Príncipe',
        locale: 'pt_ST'
    },
    {
        country_id: 210,
        isocode: 'SV',
        name: 'El Salvador',
        locale: 'es_SV'
    },
    {
        country_id: 211,
        isocode: 'SX',
        name: 'Sint Maarten',
        locale: 'nl_SX'
    },
    {
        country_id: 212,
        isocode: 'SY',
        name: 'Syria',
        locale: 'ar_SY'
    },
    {
        country_id: 213,
        isocode: 'SZ',
        name: 'Swaziland',
        locale: 'en_SZ'
    },
    {
        country_id: 214,
        isocode: 'TC',
        name: 'Turks and Caicos Islands',
        locale: 'en_TC'
    },
    {
        country_id: 215,
        isocode: 'TD',
        name: 'Chad',
        locale: 'fr_TD'
    },
    {
        country_id: 216,
        isocode: 'TF',
        name: 'French Southern Territories',
        locale: 'fr_TF'
    },
    {
        country_id: 217,
        isocode: 'TG',
        name: 'Togo',
        locale: 'fr_TG'
    },
    {
        country_id: 218,
        isocode: 'TH',
        name: 'Thailand',
        locale: 'th_TH'
    },
    {
        country_id: 219,
        isocode: 'TJ',
        name: 'Tajikistan',
        locale: 'tg_TJ'
    },
    {
        country_id: 220,
        isocode: 'TK',
        name: 'Tokelau',
        locale: 'en_TK'
    },
    {
        country_id: 221,
        isocode: 'TL',
        name: 'Timor-Leste',
        locale: 'pt_TL'
    },
    {
        country_id: 222,
        isocode: 'TM',
        name: 'Turkmenistan',
        locale: 'tk_TM'
    },
    {
        country_id: 223,
        isocode: 'TN',
        name: 'Tunisia',
        locale: 'ar_TN'
    },
    {
        country_id: 224,
        isocode: 'TO',
        name: 'Tonga',
        locale: 'en_TO'
    },
    {
        country_id: 225,
        isocode: 'TR',
        name: 'Turkey',
        locale: 'tr_TR'
    },
    {
        country_id: 226,
        isocode: 'TT',
        name: 'Trinidad and Tobago',
        locale: 'en_TT'
    },
    {
        country_id: 227,
        isocode: 'TV',
        name: 'Tuvalu',
        locale: 'en_TV'
    },
    {
        country_id: 228,
        isocode: 'TW',
        name: 'Taiwan',
        locale: 'zh_TW'
    },
    {
        country_id: 229,
        isocode: 'TZ',
        name: 'Tanzania',
        locale: 'sw_TZ'
    },
    {
        country_id: 230,
        isocode: 'UA',
        name: 'Ukraine',
        locale: 'uk_UA'
    },
    {
        country_id: 231,
        isocode: 'UG',
        name: 'Uganda',
        locale: 'sw_UG'
    },
    {
        country_id: 232,
        isocode: 'UM',
        name: 'U.S. Outlying Islands',
        locale: 'en_UM'
    },
    {
        country_id: 233,
        isocode: 'US',
        name: 'United States',
        locale: 'en_US'
    },
    {
        country_id: 234,
        isocode: 'UY',
        name: 'Uruguay',
        locale: 'es_UY'
    },
    {
        country_id: 235,
        isocode: 'UZ',
        name: 'Uzbekistan',
        locale: 'uz_UZ'
    },
    {
        country_id: 236,
        isocode: 'VA',
        name: 'Vatican City',
        locale: 'it_VA'
    },
    {
        country_id: 237,
        isocode: 'VC',
        name: 'St. Vincent & Grenadines',
        locale: 'en_VC'
    },
    {
        country_id: 238,
        isocode: 'VE',
        name: 'Venezuela',
        locale: 'es_VE'
    },
    {
        country_id: 239,
        isocode: 'VG',
        name: 'British Virgin Islands',
        locale: 'en_VG'
    },
    {
        country_id: 240,
        isocode: 'VI',
        name: 'U.S. Virgin Islands',
        locale: 'en_VI'
    },
    {
        country_id: 241,
        isocode: 'VN',
        name: 'Vietnam',
        locale: 'vi_VN'
    },
    {
        country_id: 242,
        isocode: 'VU',
        name: 'Vanuatu',
        locale: 'en_VU'
    },
    {
        country_id: 243,
        isocode: 'WF',
        name: 'Wallis and Futuna',
        locale: 'fr_WF'
    },
    {
        country_id: 244,
        isocode: 'WS',
        name: 'Samoa',
        locale: 'en_WS'
    },
    {
        country_id: 245,
        isocode: 'YE',
        name: 'Yemen',
        locale: 'ar_YE'
    },
    {
        country_id: 246,
        isocode: 'YT',
        name: 'Mayotte',
        locale: 'fr_YT'
    },
    {
        country_id: 247,
        isocode: 'ZA',
        name: 'South Africa',
        locale: 'en_ZA'
    },
    {
        country_id: 248,
        isocode: 'ZM',
        name: 'Zambia',
        locale: 'en_ZM'
    },
    {
        country_id: 249,
        isocode: 'ZW',
        name: 'Zimbabwe',
        locale: 'en_ZW'
    },
    {
        country_id: 250,
        isocode: 'XI',
        name: 'Northern Ireland',
        locale: 'en_XI'
    }
];
