import { PAGE_MANAGER_NAMES } from '@storefrontCoreFeatures/page_management/page_manager_constants';

export const PAGE_MANAGER_EVENTS = {
    loaded: `${PAGE_MANAGER_NAMES.feature}.loaded`,
    clicked: `${PAGE_MANAGER_NAMES.feature}.clicked`,
    beforeVisited: `${PAGE_MANAGER_NAMES.feature}.beforeVisited`,
    visited: `${PAGE_MANAGER_NAMES.feature}.visited`,
    submitStarted: `${PAGE_MANAGER_NAMES.feature}.submitStarted`,
    beforeFetchedRequest: `${PAGE_MANAGER_NAMES.feature}.beforeFetchedRequest`,
    beforeFetchedResponse: `${PAGE_MANAGER_NAMES.feature}.beforeFetchedResponse`,
    submitEnded: `${PAGE_MANAGER_NAMES.feature}.submitEnded`,
    beforeCached: `${PAGE_MANAGER_NAMES.feature}.beforeCached`,
    beforeRendered: `${PAGE_MANAGER_NAMES.feature}.beforeRendered`,
    rendered: `${PAGE_MANAGER_NAMES.feature}.rendered`
} as const;
