export const GOOGLE_CUSTOMER_REVIEWS_NAMES = {
    feature: 'google-customer-reviews',
    component: 'google-customer-reviews',
    service: 'GoogleCustomerReviewsService'
};

export const GOOGLE_POPUP_STYLES = {
    '1': 'CENTER_DIALOG',
    '2': 'BOTTOM_RIGHT_DIALOG',
    '3': 'BOTTOM_LEFT_DIALOG',
    '4': 'TOP_RIGHT_DIALOG',
    '5': 'TOP_LEFT_DIALOG',
    '6': 'BOTTOM_TRAY'
};

export const GOOGLE_BADGE_STYLES = {
    '1': 'BOTTOM_RIGHT',
    '2': 'BOTTOM_LEFT'
};

export const SURVEY_DATA_KEY = 'surveyData';
export const SURVEY_STORAGE_NAME = 'surveyStorage';
export const SURVEY_SESSION_STORAGE_NAME = 'surveySessionStorage';
