export const PHOTO_SLIDER_NAMES = {
    feature: 'photo-slider',
    component: 'photo-slider'
} as const;

export const PHOTO_SLIDER_ELEMENTS_CLASS_NAME = {
    progressBar: 'photo-slider__progress-bar',
    paginationCurrent: 'photo-slider__pagination-current',
    paginationLength: 'photo-slider__pagination-length'
} as const;

export const PHOTO_SLIDER_EVENTS = {
    mountedMove: 'mounted move'
} as const;
