export const LOYALTY_PRODUCT_PRICE_NAMES = {
    feature: 'loyalty-product-price',
    component: 'loyalty-product-price'
} as const;

export const LOYALTY_PRODUCT_PRICE_CLASS_NAMES = {
    loyaltyPriceContainer: 'product-price__loyalty-price',
    priceValue: 'js__price-value',
    netPricesContainer: 'js__product-price-net',
    grossPricesContainer: 'js__product-price-gross',
    priceContainer: 'js__product-price',
    priceOutsideLoyaltyProgram: 'js__price-outside-loyalty-program'
} as const;
