const featureName = 'pagination-page-number';

export const PAGINATION_PAGE_NUMBER_NAMES = {
    feature: featureName,
    component: featureName,
    api: '',
    httpApi: '',
    service: '',
    store: ''
} as const;
