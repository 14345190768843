import { FeatureCore } from '@dreamcommerce/star_core';
import { PAGE_MANAGER_EVENTS } from '@storefrontRoot/core_features/page_management/turbo_message_names';
import { IStorefrontSettingsService } from '@storefrontRoot/features/storefront_settings/storefront_settings_types';

export class PageManagerRenderedHandler extends FeatureCore {
    #service: IStorefrontSettingsService;

    constructor(service: IStorefrontSettingsService) {
        super();
        this.#service = service;

        this.eventBus.on(PAGE_MANAGER_EVENTS.rendered, this.#service.updateSettingsFromPageData);
    }
}
