import { CACHE_TYPES, ICache, IWebStore, TStoreKey, TStoreRecord, TStoreType } from '@dreamcommerce/star_core';
import { CookiesStatic } from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import {
    TCookieCacheConstructorOptions,
    TCookieCacheStoredValue
} from '@storefrontCoreFeatures/http_requester/custom_caches/cookie_cache/cookie_cache_types';
import { CookieCacheError } from '@storefrontCoreFeatures/http_requester/custom_caches/cookie_cache/cookie_cache_error';
import {
    COOKIE_CACHE_CLEAR_DATA_COOKIE_VALUE,
    COOKIE_CACHE_DEFAULT_STORE_NAME
} from '@storefrontCoreFeatures/http_requester/custom_caches/cookie_cache/cookie_cache_constants';

export class CookieCache<T> implements ICache<T> {
    public readonly moduleName;

    readonly #cookieName: string;
    readonly #storeName = COOKIE_CACHE_DEFAULT_STORE_NAME;

    #cookies: CookiesStatic;
    #store: IWebStore;
    #cacheType: TStoreType;

    get size(): number {
        return this.#store.size;
    }

    constructor({ cookieName, cookies, storeFactoryApi, cacheType }: TCookieCacheConstructorOptions) {
        this.moduleName = `CookieCache.${cookieName}`;
        this.#cookies = cookies;
        this.#cookieName = cookieName;

        this.#cacheType = cacheType ?? CACHE_TYPES.local;

        this.#store = storeFactoryApi.createStore(this.#cacheType, this.#storeName);
    }

    public get(key: TStoreKey): T | null {
        if (!this.has(key)) return null;

        const { data } = this._getStoredData();

        return data;
    }

    public set(key: TStoreKey, value: T): void {
        this.#store.setObject(this.#cookieName, {
            data: value,
            previousCacheIndex: this.#cookies.get(this.#cookieName)
        });
    }

    public has(key: TStoreKey): boolean {
        const currentCacheIndex = this.#cookies.get(this.#cookieName);

        if (this._shouldClearStorageData(currentCacheIndex)) this.clear();

        if (!this._hasStoredData()) return false;

        const { data, previousCacheIndex } = this._getStoredData();

        if (this._hasDataChanged(currentCacheIndex, previousCacheIndex) || !previousCacheIndex) {
            this.clear();

            return false;
        }

        return !isEmpty(data);
    }

    private _shouldClearStorageData(currentCacheIndex?: string): boolean {
        return !currentCacheIndex || currentCacheIndex === COOKIE_CACHE_CLEAR_DATA_COOKIE_VALUE;
    }

    private _getStoredData(): TCookieCacheStoredValue<T> {
        const storedData = this.#store.getObject<TCookieCacheStoredValue<T>>(this.#cookieName);

        if (!storedData) throw new CookieCacheError("Couldn't get stored data");

        return storedData;
    }

    private _hasStoredData(): boolean {
        return this.#store.has(this.#cookieName);
    }

    private _hasDataChanged(currentCacheIndex?: string, previousCacheIndex?: string): boolean {
        return Boolean(previousCacheIndex && !(currentCacheIndex === previousCacheIndex));
    }

    public setMultiple(storeRecord: TStoreRecord<any>[]): void {
        throw new CookieCacheError('Not implemented for this type of cache');
    }

    public getMultiple(keys: TStoreKey[]): any[] {
        return [this.#store.get(keys[0])];
    }

    public remove(key: TStoreKey): void {
        this.clear();
    }

    public clear(): void {
        this.#store.clear();
    }

    public removeMultiple(keys: TStoreKey[]): void {
        this.clear();
    }

    public dispose(): void {}
}
