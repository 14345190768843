const featureName = 'RateAndReview';

export const RATE_AND_REVIEW_NAMES = {
    feature: featureName,
    component: '',
    api: `${featureName}Api`,
    httpApi: `${featureName}HttpApi`,
    service: `${featureName}Service`,
    store: `${featureName}Store`
} as const;
