export const FLASH_MESSAGE_TYPES = {
    info: 'info',
    notice: 'notice',
    success: 'success',
    warning: 'warning',
    error: 'error'
} as const;

export const FLASH_MESSAGE_DEFAULT_ICON_NAMES = {
    info: 'info',
    notice: 'info',
    success: 'check',
    warning: 'info',
    error: 'alert-triangle'
} as const;

export const FLASH_MESSAGE_DEFAULT_ICON_SIZES = {
    sizeL: 'l',
    sizeXl: 'xl',
    sizeXxl: 'xxl',
    sizeXxxl: 'xxxl'
} as const;

export const DEFAULT_FLASH_MESSAGE_TIMEOUT = 6000;
export const DEFAULT_LONG_FLASH_MESSAGE_TIMEOUT = 10000;
export const SHORT_FLASH_MESSAGE_MAX_LENGTH = 100;

// poniżej tej wartości ucina animacje
export const MINIMUM_MESSAGE_TTL_IN_MS = 350;
