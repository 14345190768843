import { EventMessage, FEATURE_REGISTRATION_EVENTS, FeatureCore, TFeatureCore, TRegisteredFeature } from '@dreamcommerce/star_core';
import {
    COMPONENTS_CONTAINER_NAME,
    FEATURES_REGISTRATION_OBSERVER_NAME
} from '@storefrontCoreFeatures/components/management/components_management_constants';
import { TFeaturesObserverConstructorOptions } from '@storefrontCoreFeatures/components/management/components_management_types';
import { IComponentsResolver } from '@storefrontCoreFeatures/components/management/resolver/components_resolver_types';
import { ArrayUtils } from '@dreamcommerce/utilities';

export class FeaturesRegistrationObserver extends FeatureCore {
    public readonly moduleName = FEATURES_REGISTRATION_OBSERVER_NAME;

    #componentsResolver: IComponentsResolver;

    constructor({ componentsResolver }: TFeaturesObserverConstructorOptions) {
        super();

        this.#componentsResolver = componentsResolver;

        this._setupFeatureRegistrationListeners();
    }

    private _setupFeatureRegistrationListeners(): void {
        this.eventBus.on(FEATURE_REGISTRATION_EVENTS.featureRegistered, this._handleRegisteredFeature);
    }

    private _handleRegisteredFeature = ({ body }: EventMessage<TRegisteredFeature>): void => {
        if (!body) return;

        const { featureRecord } = body;

        const cores = featureRecord.cores;

        const componentCore = cores.find((core: TFeatureCore) => core.type === COMPONENTS_CONTAINER_NAME);

        if (!componentCore) return;

        this.#componentsResolver.registerComponents(ArrayUtils.makeAsArray(componentCore.instance));
    };
}
