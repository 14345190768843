import { EventMessage, FeatureCore } from '@dreamcommerce/star_core';
import { PAGE_MANAGER_EVENTS } from '@storefrontRoot/core_features/page_management/turbo_message_names';
import { PAGE_MANAGER_LOADED_HANDLER_NAME } from '@storefrontCoreFeatures/page_management/events/page_manager_events_constants';

export class PageManagerLoadedHandler extends FeatureCore {
    public readonly moduleName = PAGE_MANAGER_LOADED_HANDLER_NAME;

    constructor(callback: (event: EventMessage<CustomEvent>) => void) {
        super();

        this.eventBus.on(PAGE_MANAGER_EVENTS.loaded, callback);
    }
}
